module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.12.3_gatsby@5.12.9_babel-eslint@10.1.0_eslint@8.52.0__react-do_84cf32de23dd1667b9807d99129de31c/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Digital Spiders","short_name":"Digital Spiders","start_url":"/","background_color":"#6500e5","theme_color":"#6500e5","display":"minimal-ui","icon":"src/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"955c7f1d9043a51394caf753e123c6b7"},
    },{
      plugin: require('../node_modules/.pnpm/@sentry+gatsby@7.77.0_gatsby@5.12.9_babel-eslint@10.1.0_eslint@8.52.0__react-dom@18.2.0_83656c05f440f52b3a850b94cb310c99/node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-tagmanager@5.12.3_gatsby@5.12.9_babel-eslint@10.1.0_eslint@8.52.0__c282e3545f84b89af3fe36cbe38eaa51/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NQF9NZP9","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.12.9_babel-eslint@10.1.0_eslint@8.52.0__react-dom@18.2.0_react@18.2.0__react@1_59f50ae13c0a55b81c323bd90688d22e/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
